<template>
  <v-form>
    <v-row>
      <v-col cols="4">
        <v-text-field outlined dense label="Code" v-model="record.code"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field outlined dense label="Tax Group Name" v-model="record.name"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.category"
          :items="categories"
          item-text="name"
          item-value="value"
          label="Category"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-text-field outlined dense label="Rate %" v-model="record.rate"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-radio-group v-model="inactive" row label="Active">
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    record: {},
    loader: false,
    inactive: 'Yes',
    categories: [
      { name: "Input", value: "I" },
      { name: "Output", value: "O" }
    ]
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.record.inactive = this.inactive;
      this.$emit("data", this.record);
    }
  }
};
</script>